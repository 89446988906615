<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div
    v-if="fileContent && getVolumeNameListCompose[tableItem.serviceName].length"
    class="d-flex justify-end"
  >
    <v-checkbox
      :id="`iiotRestartOnConfigurationUpdate_${index}`"
      :data-cy="`iiotRestartOnConfigurationUpdate_${index}`"
      v-model="tableItem.dockerConfigurationStorage.restartOnConfigurationUpdate"
      :disabled="version.released || (!tableItem.dockerConfigurationStorage.volumeName
      || tableItem.dockerConfigurationStorage.volumeName === $t('workloadVersion.emptyState'))"
      @change="checkboxHasChanged"
    />
  </div>
</template>

<script>
export default {
  data: () => ({
    isRestartOnConfigurationUpdateDisabled: false,
  }),
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    fileContent() {
      return this.$store.getters['workloads/fileContent'];
    },
    getVolumeNameListCompose() {
      return this.$store.getters['workloads/getVolumeNameListCompose'];
    },
    version() {
      return this.$store.getters['workloads/getVersion'];
    },
  },
  methods: {
    checkboxHasChanged() {
      if (this.version.services.find((service) => service.file.status !== 'available')) {
        this.$store.dispatch('workloads/set_save_enabled');
        return;
      }
      this.$store.dispatch('workloads/set_save_enabled', this.version);
    },
  },
};
</script>
